import React from 'react';

const InputType = ({ label, type, placeholder, textarea }) => {
    return (
        <label className="block my-2">
            <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                {label ? label : ""}
            </span>
            {textarea ? (
                <textarea
                    type={type ? type : "text"}
                    name="email"
                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder={placeholder ? placeholder : "Write here ..."}
                ></textarea>

            ) : (
                <input
                    type={type ? type : "text"}
                    name="email"
                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder={placeholder ? placeholder : "Write here ..."}
                />
            )}

        </label>
    );
};

export default InputType;