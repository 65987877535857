import React from 'react';

const TestimonialCard = ({ comment, name, place }) => {
    return (
        <div className="bg-white light:bg-slate-900 rounded-lg p-4 ring-1 ring-slate-900/5 shadow-xl text-left">
            <blockquote className="text-lg font-semibold italic text-center text-slate-600 my-4">
                "{comment ? comment : ""}"
            </blockquote>
            <div className="group flex items-center">
                <img
                    className="shrink-0 h-12 w-12 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                    alt=""
                />
                <div className="ml-3 rtl:ml-0 rtl:mr-3">
                    <p className="text-sm font-medium text-slate-500 group-hover:text-slate-700 dark:group-hover:text-slate-300">
                        {name ? name : ""}
                    </p>
                    <p className="text-sm font-medium text-slate-700 group-hover:text-slate-900 dark:text-slate-300 dark:group-hover:text-white">
                        {place ? place : ""}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;