import { getSession } from "core/utility";
import { UserAccessToken } from "./constant";

const localStorageKey = 'lb-vendor-website-token'
function logout() {
  window.localStorage.removeItem(localStorageKey)
}

const handleResponse = async (response) => {
  if (response.status === 401) {
    logout()
    return
  }

  if (!response.ok) {
    throw new Error(response.status);
  }
  return response.json();
};

const handleError = async (error) => {
  return error;
};

export const httpService = async (endpoint, { body, ...customConfig }) => {
  const headers = { 'content-type': 'application/json' }
  if (getSession(UserAccessToken)) {
    headers.Authorization = `Bearer ${getSession(UserAccessToken)}`
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }
  const url = `api/${endpoint}`;

  try {
    const response = await fetch(url, config);

    return await handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

