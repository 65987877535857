import React from 'react';

function ViewImage({ imageUrl, closeImg }) {
    const closeView = () => {
        closeImg(true);
    }
    return (
        <div className={`backdrop-blur-2xl fixed  bottom-0 top-0 left-0 right-0 z-50 p-8`}>
            <div className='absolute right-5 top-5' onClick={closeView}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 cursor-pointer" >
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                </svg>

            </div>
            <div className="w-full h-full p-2 bg-white light:bg-slate-900 rounded-lg ring-1 ring-slate-900/5 shadow-xl text-left">
                <img className="rounded object-cover w-full h-full" src={imageUrl} alt="no Gallery image" />
            </div>
        </div>
    );
}

export default ViewImage;