import React from 'react';

function NoData({text}) {
    return (
        <div className="mt-2 text-lg dark:text-dark tracking-tight text-center ">
            {text}
        </div>
    );
}

export default NoData;