import React from 'react';
export default function Footer() {
    return (
        <footer className="bg-gray-800 p-6">
            <div className="flex justify-center items-center space-x-4">
                <a href="https://www.facebook.com/yourpage" target={"_blank"}>
                    <img
                        src="image/icons/facebook.png"
                        alt="Facebook"
                        className="w-10 h-10"
                    />
                </a>
                <a href="https://www.twitter.com/yourpage" target={"_blank"}>
                    <img
                        src="image/icons/twitter.png"
                        alt="Twitter"
                        className="w-10 h-10"
                    />
                </a>
                <a href="https://www.instagram.com/yourpage" target={"_blank"}>
                    <img
                        src="image/icons/instagram.png"
                        alt="Instagram"
                        className="w-10 h-10"
                    />
                </a>
                <a href="https://www.instagram.com/yourpage" target={"_blank"}>
                    <img
                        src="image/icons/youtube.png"
                        alt="Instagram"
                        className="w-10 h-10"
                    />
                </a>
            </div>
            <p className="mt-4 text-center text-white">
                &copy; 2024 Your Website Name. All rights reserved.
            </p>
        </footer>
    );
}