import React from 'react';

const PlaceholderLoader = ({ className, onlyContent, numberOfCard }) => {

    // Use Array.from to generate HTML elements based on the count prop
    const renderedItems = Array.from({ length: numberOfCard || 1 }, (_, index) => (
        <div key={index} class="flex-1">

            <div className={`m-auto bg-white light:bg-slate-800 p-4 ring-1 ring-slate-900/5 rounded-lg shadow-lg w-full h-28`}>
                <div className="flex space-x-4 animate-pulse">
                    {onlyContent ? "" : <div className="rounded-full bg-slate-200 light:bg-slate-700 h-10 w-10"></div>}
                    <div className="flex-1 space-y-6 py-1">
                        <div className="h-2 bg-slate-200 light:bg-slate-700 rounded"></div>
                        <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-slate-200 light:bg-slate-700 rounded col-span-2"></div>
                                <div className="h-2 bg-slate-200 light:bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-200 light:bg-slate-700 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ));

    return (
        <div className={className ? className : ''}>
            <div class="flex flex-col gap-2 md:flex-row my-6">
                {renderedItems}
            </div>
        </div>
    );
};

export default PlaceholderLoader;